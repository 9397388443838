<template>
  <div class="main-home">
    <div class="home-banner">
      <div class="btn" @click="retrospect">往期回顾</div>
    </div>
    <div class="content-box">
      <div class="my-integral box-padding">
        <div class="integral-title" @click="gotoMypoints">
          我的积分
          <span>查看<img src="~images/dow-ceremony/left-arrow.png"></span>
        </div>
        <ul>
          <li @click="showActiveRuleDialog">
            <b>{{monthPoints.seasonPoints}}</b>
            <p>本期积分 <img src="~images/dow-ceremony/mark.png"></p>
          </li>
          <li @click="showDanRuleDialog">
            <b>{{monthPoints.seasonPoints | level}}</b>
            <p>当前段位 <img src="~images/dow-ceremony/mark.png"></p>
          </li>
          <li>
            <b>{{monthPoints.seasonRank}}</b>
            <p>本期排名</p>
          </li>
        </ul>
        <!--排行榜-->
        <div class="ranking-list" v-if="isLogon">
          积分总排名:
          <i>{{monthPoints.rank}}</i>
          <span @click="gotoRankList">排行榜</span>
        </div>
        <div v-else class="ranking-login">
          <span class="draw-btn" @click="gotoLogin">立即登录，参与活动</span>
        </div>
        <div class="active-rule" @click="gotoActiveRule">活动规则</div>
      </div>
      <!----抽奖专区---->
      <div class="integral box-padding">
        <div class="integral-title">
          奖品专区
        </div>
        <div class="notice">
          <div class="notice-img"><img src="~images/dow-ceremony/notice.png"></div>
          <div class="notice-prize-right">
            <noticePrize :color="colorStyle"></noticePrize>
          </div>
        </div>
        <div class="porduct">
          <ul>
            <li><img src="~images/dow-ceremony/prize-pc.jpg" alt="">
              <p>总积分第一名</p>
            </li>
            <li><img src="~images/dow-ceremony/prize-phone.png" alt="">
              <p>一等奖</p>
            </li>
            <li><img src="~images/dow-ceremony/prize-car.png" alt="">
              <p>二等奖</p>
            </li>
            <li><img src="~images/dow-ceremony/prize-card.png" alt="">
              <p>三等奖</p>
            </li>
          </ul>
        </div>
        <!---------抽奖按钮---------->
        <div class="luck-draw">
          <span class="draw-btn" @click="gotoLuckyDraw">立即抽奖</span>
          <p v-if="isLogon">剩余 {{monthPoints.availablePrize}} 次抽奖机会</p>
        </div>
        <div class="active-rule" @click="gotoDrawRules">抽奖规则</div>
      </div>
      <!----扫码送积分---->
      <div class="send-integral  box-padding">
        <span>已获积分：{{monthPoints.seasonCodePoints}}</span>
      </div>
      <!----大大汽修积分---->
      <div class="integral box-padding">
        <div class="integral-title">
          大大汽修-送积分
        </div>
        <div class="integral-list">
          <ul v-for="(task, index) in taskOrder" :key="index" @click="shareAndGoBackHome(index)">
            <li>
              <img src="~images/dow-ceremony/icon-05.png" v-if="task.type === 'REGISTERED'">
              <img src="~images/dow-ceremony/icon-01.png" v-if="task.type === 'EVERY_MONTH_CREATE_ORDER'">
              <img src="~images/dow-ceremony/icon-04.png" v-if="task.type === 'EVERY_DAY_CREATE_ORDER'">
              <img src="~images/dow-ceremony/icon-02.png" v-if="task.type === 'EVERY_DAY_SHARE'">
              <img src="~images/dow-ceremony/icon-03.png" v-if="task.type === 'INVITATION'">
              <p class="font-title">{{task.type | orderType}}</p>
              <p class="font-subtitle">任务完成次数
                <span :class="[task.status === 0?'font-completion':'']">{{task.count}}/{{task.totalCount}}</span>
              </p>
              <div class="completion">
                <span :class="[task.status === 0?'btn-hang':'btn-completed']">{{task.status | completion}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="dan-rules">
      <p v-if="clientAgent === 'ios'">本活动与苹果公司无关 </p>
      <p>本次活动最终解释权归 </p>
      <p>上海达欧百希特汽车配件有限公司所有</p>
    </div>
    <div class="download-app" @click="gotoDownLoad" v-if="isDownLoad">
      下载APP
    </div>
  </div>
</template>

<script>
import noticePrize from 'components/notice-prize/index'
import { setSessionStorage, getSessionStorage, setCookie, getCookie } from 'utils/store'
import { getUserId } from 'api/user'
import { ruleOfDialog, activeOfDialog, monthPoints } from 'components/bomb-box/index.js'
import { getMyPointsSummary, taskCompletion } from 'api/my-points'
import { ModalHelper } from 'utils'
export default {
  components: {
    noticePrize
  },
  data () {
    return {
      token: '',
      monthPoints: {
        seasonCodePoints: '---',
        availablePrize: '---',
        rank: '---',
        seasonPoints: '---',
        seasonRank: '---'
      },
      taskOrder: (() => {
        let tasks = []
        let types = [
          'REGISTERED',
          'EVERY_MONTH_CREATE_ORDER',
          'EVERY_DAY_CREATE_ORDER',
          'EVERY_DAY_SHARE',
          'INVITATION'
        ]
        types.forEach((element, index) => {
          tasks.push({
            type: element,
            count: 0,
            status: 0,
            totalCount: index === 2 ? 4 : 1
          })
        })
        return tasks
      })(),
      rank: {},
      isLogon: false,
      isDownLoad: false,
      clientAgent: '',
      colorStyle: {
        li: '#000',
        i: '#FC4C5A'
      },
      seasonId: 0
    }
  },
  computed: {
  },
  mounted () {
    this.initData()
  },
  methods: {
    initDownloadApp () {
      if (this.$store.state.shouldPopUpAppDownload) {
        this.$store.commit('SHOULD_SHOW_APP_DOWNLOAD', false)
        ModalHelper.onOpen()
        let self = this
        this.$createDialog({
          showClose: true,
          onClose: () => {
            ModalHelper.onClose()
          }
        }, (createElement) => {
          return [
            createElement('div', {
              'class': {
                'my-download-title': true
              },
              slot: 'title'
            }, [createElement('div', {
              'class': {
                'my-content-download-title': true
              }
            })]),
            createElement('div', {
              'class': {
                'my-content-download-img': true
              },
              slot: 'content'
            }, ``),
            createElement('div', {
              'class': {
                'my-content-download-app': true
              },
              slot: 'content'
            }, `下载"大大汽修"APP`),
            createElement('div', {
              'class': {
                'my-content-download-points': true
              },
              slot: 'content'
            }, '完成任务获取更多积分'),
            createElement('div', {
              'class': {
                'my-content-download-button': true
              },
              slot: 'content',
              on: {
                click: function (e) {
                  ModalHelper.onClose()
                  self.gotoDownLoad()
                }
              },
              confirm: 'ticket'
            }, '立即下载APP')
          ]
        }
        ).show()
      }
    },
    initData () {
      if (this.$route.query['token']) {
        setSessionStorage('token', this.$route.query['token'])
        setCookie('token', this.$route.query['token'])
      }
      if (this.$route.query['fromUser']) {
        setSessionStorage('fromUser', this.$route.query['fromUser'])
      }

      this.clientAgent = this.$route.query['type']
      if (this.$route.query['type'] === 'Android' || this.$route.query['type'] === 'ios') {
        this.isDownLoad = false
      } else {
        this.isDownLoad = true
        let token = getSessionStorage('token')
        if (token) {
          this.initDownloadApp()
        }
      }
      getUserId({}).then(res => {
        if (res.data.result !== 0) {
          return
        }
        this.isLogon = true
        setSessionStorage('userId', res.data.data)
        this.getPoints()
        this.getTaskCompletion()
      })
    },
    getPoints () {
      let params = {}
      getMyPointsSummary(JSON.stringify(params)).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.monthPoints = res.data.data
        this.monthPoints.seasonCodePoints = res.data.data.seasonCodePoints || 0
        this.monthPoints.availablePrize = res.data.data.availablePrize || 0
        this.monthPoints.rank = res.data.data.rank || 0
        this.monthPoints.seasonPoints = res.data.data.seasonPoints || 0
        this.monthPoints.seasonRank = res.data.data.seasonRank || 0
        this.seasonId = res.data.data.seasonId || 0
        let task = {}
        task.id = this.seasonId
        setSessionStorage('task', task)
      })
    },
    getTaskCompletion () {
      taskCompletion({}).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.taskOrder = res.data.data || {}
      })
    },
    retrospect () {
      window.open('http://q.phc-dow.com/credits/index.html')
    },
    gotoLogin () {
      this.$router.push({ path: '/dc/login' })
    },
    gotoMypoints () {
      this.$router.push({ path: '/dc/my-points' })
    },
    gotoRankList () {
      this.$router.push({ name: '排行榜' })
    },
    gotoLuckyDraw () {
      this.$router.push({ path: '/dc/lucky-draw' })
    },
    gotoActiveRule () {
      this.$router.push({ path: '/dc/activity-rules' })
    },
    gotoDrawRules () {
      this.$router.push({ path: '/dc/draw-rules' })
    },
    gotoDownLoad () {
      this.$router.push({ path: '/dc/download' })
    },
    showDanRuleDialog () {
      ModalHelper.onOpen()
      this.$createDialog({
        showClose: true,
        onClose: () => {
          ModalHelper.onClose()
        }
      }, ruleOfDialog).show()
    },
    showActiveRuleDialog () {
      ModalHelper.onOpen()
      this.$createDialog({
        showClose: true,
        onClose: () => {
          ModalHelper.onClose()
        }
      }, activeOfDialog).show()
    },
    showMonthPointDialog () {
      ModalHelper.onOpen()
      this.$createDialog({
        showClose: true,
        onClose: () => {
          ModalHelper.onClose()
        }
      }, monthPoints).show()
    },
    goBackAppHome () {
      if (this.$route.query['type'] === 'Android') {
        // eslint-disable-next-line
        goBack.onBackHome()
      } else if (this.$route.query['type'] === 'ios') {
        // eslint-disable-next-line
        window.webkit.messageHandlers.onBackHome.postMessage({})
      }
    },
    goBackAppShare () {
      if (this.$route.query['type'] === 'Android') {
        // eslint-disable-next-line
        goBack.share()
      } else if (this.$route.query['type'] === 'ios') {
        // eslint-disable-next-line
        window.webkit.messageHandlers.share.postMessage({})
      }
    },
    shareAndGoBackHome (index) {
      let token = getSessionStorage('token') || getCookie('token')
      let type = this.$route.query['type']
      // 判断是否登陆，登陆则执行后续，未登录则5个全部跳转到登陆页
      if (!token) {
        this.$router.push({ path: '/dc/login' })
        return
      }
      // 判断是否是APP内进入  如果APP 则执行后续操作，如果是h5 则全部跳转到下载页
      if (this.taskOrder[index].status !== 0) {
        return
      }
      if (type !== 'Android' && type !== 'ios') {
        this.$router.push({ path: '/dc/download' })
        return
      }
      // 判断index为0,1,2时，如果是未完成状态，点击则跳转到APP首页
      // 判断index为3, 4时，如果是未完成状态，点击则执行APP分享功能
      switch (index) {
        case 0:
        case 1:
        case 2:
          this.goBackAppHome()
          break
        case 3:
        case 4:
          this.goBackAppShare()
          break
      }
    }
  }
}
</script>
 <style lang="scss" scoped>
@import "~styles/index.scss";
.main-home {
  position: relative;
  background: #052863;
  height: auto;
  padding-bottom: px2rem(50px);
  .home-banner {
    background: url(~images/dow-ceremony/banner.png) no-repeat;
    width: px2rem(750px);
    height: px2rem(380px);
    background-size: 100%;
    position: relative;
    .btn {
      color: #fff;
      width: px2rem(150px);
      height: px2rem(60px);
      text-align: center;
      border-radius: 30px 0 0 30px;
      font-size: px2rem(28px);
      line-height: px2rem(60px);
      background-image: linear-gradient(
        222deg,
        rgba(255, 255, 255, 0.03),
        rgba(255, 242, 223, 0.26)
      );
      border: solid 1px rgba(255, 255, 255, 0.7);
      border-right: none;
      position: absolute;
      right: 0px;
      bottom: px2rem(50px);
    }
  }
  .content-box {
    background: url(~images/dow-ceremony/home-bg.png) #052863 repeat-x;
    background-size: 100%;
    width: px2rem(750px);
    .integral {
      width: px2rem(690px);
      background: #fff;
      border-radius: 6px;
      padding: px2rem(40px) px2rem(30px) 0;
      box-sizing: border-box;
      margin-bottom: px2rem(30px);
      .integral-title {
        height: px2rem(50px);
        font-weight: bolder;
        text-align: center;
        position: relative;
        font-size: px2rem(36px);
        margin-bottom: px2rem(20px);
        & span {
          position: absolute;
          right: 10px;
          color: #fc4c5a;
          display: inline-block;
          font-weight: bold;
          font-size: px2rem(28px);

          & img {
            width: px2rem(10px);
            height: px2rem(20px);
            margin-top: 2px;
            padding-left: px2rem(10px);
          }
        }
      }
    }
    .my-integral {
      width: px2rem(690px);
      background: #fff;
      border-radius: 6px;
      padding: 0 px2rem(20px) 0;
      box-sizing: border-box;
      margin-bottom: px2rem(30px);
      .integral-title {
        margin: px2rem(40px) auto 0;
        line-height: px2rem(40px);
        font-weight: bolder;
        text-align: center;
        position: relative;
        font-size: px2rem(36px);
        & span {
          position: absolute;
          right: 10px;
          color: #fc4c5a;
          display: inline-block;
          font-weight: bold;
          font-size: px2rem(28px);

          & img {
            width: px2rem(10px);
            height: px2rem(20px);
            margin-top: 2px;
            padding-left: px2rem(10px);
          }
        }
      }
      & ul {
        width: 100%;
        display: inline-table;
        // padding: 0 px2rem(40px);
        height: px2rem(73px);
        & li {
          display: table-cell;
          width: 33.33%;
          text-align: center;
          font-size: px2rem(36px);
          & b {
            color: $color-highlight;
            font-weight: bold;
            font-size: px2rem(36px);
            display: inline-block;
          }
          & p {
            color: #999;
            margin-top: px2rem(20px);
            font-size: px2rem(24px);
            position: relative;
            & img {
              width: px2rem(28px);
              height: px2rem(28px);
              position: absolute;
              bottom: 0;
              right: px2rem(25px);
              // margin: -1% auto 0;
              // transform: translateY(26%);
              // margin: -11% auto 0;
              // transform: translateY(-11%);
              // margin-right: px2rem(85px);
              // display: block;
            }
          }
        }
        li:first-child {
          border-right: 1px solid #e4e4e4;
        }
        li:last-child {
          border-left: 1px solid #e4e4e4;
        }
      }
    }

    /*我的积分 end*/
    .notice {
      background: $color-main-bg;
      border-radius: 6px;
      width: px2rem(630px);
      height: px2rem(70px);
      font-size: px2rem(24px);
      box-sizing: border-box;
      padding-left: 10px;
      position: relative;
      .notice-img {
        float: left;
        width: px2rem(90px);
        height: px2rem(70px);
        img {
          width: px2rem(63px);
          height: px2rem(26px);
          vertical-align: middle;
          margin-top: px2rem(23px);
          border-right: 1px solid #c8d2e4;
          padding-right: px2rem(20px);
        }
      }
      .notice-prize-right {
        width: px2rem(485px);
        height: px2rem(70px);
        padding: 0 px2rem(10px);
        float: left;
      }
    }
    /*公告 end*/
    .porduct {
      width: px2rem(630px);
      margin: px2rem(30px) 0;
      li {
        width: px2rem(305px);
        float: left;
        text-align: center;
        margin: 0 px2rem(18px) 0 0;
        & img {
          width: px2rem(305px);
          height: px2rem(216px);
        }
        & p {
          font-size: px2rem(28px);
          // line-height: px2rem(50px);
          margin: px2rem(10px) 0 px2rem(35px) 0;
        }
      }
      & li:nth-child(even) {
        margin: 0;
      }
    }
    .luck-draw {
      width: px2rem(472px);
      margin: 0 auto;
      text-align: center;
      & p {
        color: $color-highlight;
        font-size: px2rem(24px);
        padding: px2rem(22px) 0 px2rem(6px) 0;
      }
    }
    /*扫码送积分*/
    .send-integral {
      width: px2rem(690px);
      height: px2rem(238px);
      background: url(~images/dow-ceremony/send-integral.png);
      background-size: 100%;
      position: relative;
      margin: px2rem(30px) auto;
      & span {
        position: absolute;
        bottom: px2rem(39px);
        left: px2rem(26px);
        color: #fff;
        font-size: px2rem(28px);
      }
    }
    /*大大汽修积分*/
    .integral-list {
      & ul {
        border-bottom: 1px solid $color-line;
        width: px2rem(630px);
        height: px2rem(80px);
        padding: px2rem(29px) 0;
        position: relative;
        & li {
          float: left;
          width: 100%;
          & img {
            width: px2rem(80px);
            height: px2rem(80px);
            float: left;
            padding-right: px2rem(20px);
          }
          .font-title {
            margin: px2rem(8px) 0 px2rem(5px);
            color: $color-text;
            font-size: px2rem(28px);
            line-height: px2rem(40px);
          }
          .font-subtitle {
            color: #999;
            font-size: px2rem(24px);
            .font-completion {
              color: #fc4c5a;
            }
          }
          .completion {
            position: absolute;
            width: px2rem(80px);
            height: px2rem(40px);
            text-align: center;
            font-size: px2rem(24px);
            display: inline-block;
            bottom: px2rem(48px);
            right: px2rem(30px);
            .btn-hang {
              width: px2rem(90px);
              height: px2rem(40px);
              line-height: px2rem(41px);
              color: $color-highlight;
              background: #fceff0;
              display: inline-block;
              border-radius: px2rem(20px);
              box-sizing: border-box;
              vertical-align: middle;
            }
            .btn-completed {
              width: px2rem(90px);
              height: px2rem(40px);
              line-height: px2rem(41px);
              color: #999;
              background: $color-main-bg;
              display: inline-block;
              border-radius: px2rem(20px);
              box-sizing: border-box;
              vertical-align: middle;
            }
          }
        }
      }
      & ul:last-child {
        border-bottom: none;
      }
    }
  }
}
.ranking-list {
  width: px2rem(650px);
  height: px2rem(116px);
  background: url(~images/dow-ceremony/ranking-list.png) no-repeat;
  background-size: 100%;
  text-align: center;
  color: #fff;
  line-height: px2rem(127px);
  font-size: px2rem(32px);
  position: relative;
  box-sizing: border-box;
  margin-top: px2rem(27px);
  & i {
    font-weight: bold;
    font-style: normal;
  }
  & span {
    box-sizing: border-box;
    position: absolute;
    right: px2rem(14px);
    bottom: px2rem(19px);
    line-height: px2rem(67px);
    width: px2rem(151px);
    background: linear-gradient(
      132.9deg,
      rgba(255, 152, 91, 1),
      rgba(255, 63, 79, 1)
    );
    border-radius: 42px;
    color: #fff;
    font-size: px2rem(36px);
    font-weight: bold;
    display: block;
  }
}
.dan-rules {
  width: px2rem(750px);
  height: px2rem(66px);
  line-height: px2rem(36px);
  text-align: center;
  color: #6791c7;
  font-size: px2rem(24px);
  // padding-bottom: px2rem(40px);
}
.download-app {
  position: fixed;
  width: px2rem(100px);
  height: px2rem(100px);
  border-radius: 100%;
  background: #4081d6;
  color: #fff;
  font-size: px2rem(28px);
  right: px2rem(30px);
  bottom: px2rem(30px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  line-height: px2rem(26px);
}
.active-rule {
  width: 100%;
  // height: px2rem(60px);
  text-align: center;
  font-size: px2rem(24px);
  // line-height: px2rem(60px);
  padding: px2rem(20px) 0 px2rem(44px) 0;
  color: #052863;
}
.ranking-login {
  width: px2rem(650px);
  text-align: center;
  margin: px2rem(40px) 0 0 0;
}
.draw-btn {
  width: px2rem(472px);
  height: px2rem(90px);
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(
    132.9deg,
    rgba(255, 152, 91, 1),
    rgba(255, 63, 79, 1)
  );
  border-radius: 45px;
  color: #fff;
  line-height: px2rem(90px);
  font-size: px2rem(28px);
}
</style>
